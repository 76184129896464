import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Billing from '~/components/Billing';
import PagePayment from '~/components/PagePayment';
import LoginPending from '~/layouts/components/LoginPending';
import FormLogin from '~/layouts/components/LoginGarena/FormLogin';

const publicRoutes = [
    { path: '/', component: Home },
    { path: '/success/:id', component: Billing },
    { path: '/garena-login', component: FormLogin },
    { path: '*', component: NotFound, layout: null },
    { path: '/google-login', component: LoginPending },
    { path: '/facebook-login', component: LoginPending },
    { path: '/payment/:request_id', component: PagePayment },
];

export default publicRoutes;
