import { Link } from 'react-router-dom';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestLoginAccountID } from '~/services/auth';
import { loginAccountSuccess, logoutAccountSuccess } from '~/redux/reducer/auth';

function LoginAccount() {
    const [accountID, setAccountID] = useState('');

    const inputLoginID = document.getElementById('account_id');
    const btnLoginId = document.getElementById('btn-login-id');
    const errorLoginID = document.getElementById('error-login-id');

    const dispatch = useDispatch();

    const { auth_account } = useSelector((state) => state.auth);

    const handleOnChangeAccountID = (value) => {
        setAccountID(value);

        if (errorLoginID) {
            errorLoginID.classList.add('hidden');
        }
        if (inputLoginID) {
            inputLoginID.classList.remove('form-input-invalid');
        }
    };

    const handleLoginAccountID = async () => {
        if (!accountID || accountID.length < 7 || accountID.length > 11) {
            inputLoginID.classList.add('form-input-invalid');
            errorLoginID.classList.remove('hidden');
            errorLoginID.innerText = 'ID người chơi không hợp lệ';
            return;
        }

        btnLoginId.classList.add('relative', 'btn-loading');
        btnLoginId.innerText = '';
        btnLoginId.style.width = '112px';
        btnLoginId.disabled = true;
        const data = {
            account_id: accountID,
        };
        const result = await requestLoginAccountID(data);

        btnLoginId.classList.remove('relative', 'btn-loading');
        btnLoginId.innerText = 'Đăng nhập';
        btnLoginId.style.width = 'auto';
        btnLoginId.disabled = false;
        if (result.status === 200) {
            dispatch(loginAccountSuccess(result.data));
        } else {
            inputLoginID.classList.add('form-input-invalid');
            errorLoginID.classList.remove('hidden');
            errorLoginID.innerText = result.error;
        }
    };

    const handleToggleModalLogout = () => {
        const modal = document.getElementById('modal-logout-id');
        modal.classList.toggle('hidden');
    };

    const handleLogoutAccountID = async () => {
        dispatch(logoutAccountSuccess());
        handleToggleModalLogout();
    };

    return (
        <div className="group md:max-w-[464px]">
            <div className="mb-3 flex items-center gap-2 text-lg/none text-text-title md:text-xl/none">
                <div className="grid items-center">
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="col-start-1 row-start-1 text-2xl text-primary-red"
                    >
                        <path
                            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15.7574C24 16.553 23.6839 17.3161 23.1213 17.8787L17.8787 23.1213C17.3161 23.6839 16.553 24 15.7574 24H3C1.34315 24 0 22.6569 0 21V3Z"
                            fill="currentColor"
                        ></path>
                    </svg>

                    <div className="col-start-1 row-start-1 text-center text-base/none font-bold text-white">1</div>
                </div>
                <span className="font-bold">Đăng nhập</span>

                {auth_account && (
                    <Fragment>
                        <div className="fixed inset-0 z-50 hidden" id="modal-logout-id">
                            <div className="absolute inset-0 bg-black/60"></div>
                            <div className="absolute inset-0 grid overflow-auto justify-items-center items-center">
                                <div className="" id="headlessui-dialog-panel-:r2l:" data-headlessui-state="open">
                                    <div className="w-screen p-2">
                                        <div className="relative mx-auto flex w-full max-w-80 flex-col gap-5 rounded-lg bg-white p-6 text-center">
                                            <div className="flex flex-col gap-3">
                                                <h2
                                                    className="text-base/none font-bold text-text-title"
                                                    id="headlessui-dialog-title-:r2m:"
                                                    data-headlessui-state="open"
                                                >
                                                    Bạn muốn đăng xuất?
                                                </h2>
                                                <p
                                                    className="text-sm/[22px] text-text-secondary"
                                                    id="headlessui-description-:r2n:"
                                                    data-headlessui-state="open"
                                                >
                                                    Đăng xuất khỏi tài khoản hiện tại
                                                </p>
                                            </div>
                                            <div className="flex gap-4">
                                                <button
                                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red text-primary-red hover:bg-white-hover bg-white px-5 text-sm font-bold h-10 w-full"
                                                    onClick={handleToggleModalLogout}
                                                >
                                                    Quay lại
                                                </button>
                                                <button
                                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-sm font-bold h-10 w-full"
                                                    onClick={handleLogoutAccountID}
                                                >
                                                    Đăng xuất
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="logout-button ms-auto flex items-center text-sm/none text-primary-red transition-opacity hover:opacity-70 group-[.loading]:pointer-events-none group-[.loading]:opacity-50"
                            onClick={handleToggleModalLogout}
                        >
                            <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 80 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-1"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M53.048 11.8069C51.8367 10.6764 49.9383 10.7418 48.8078 11.953C47.6773 13.1643 47.7428 15.0626 48.954 16.1932L58.3898 25H14.0007C12.3439 25 11.0007 26.3432 11.0007 28C11.0007 29.6569 12.3439 31 14.0007 31H66.0007C67.233 31 68.3399 30.2465 68.7917 29.1001C69.2436 27.9538 68.9485 26.6476 68.0477 25.8069L53.048 11.8069ZM26.9539 68.1932C28.1652 69.3237 30.0636 69.2582 31.1941 68.0469C32.3245 66.8356 32.259 64.9373 31.0477 63.8068L21.6114 55H66.0001C67.657 55 69.0001 53.6569 69.0001 52C69.0001 50.3432 67.657 49 66.0001 49H14.0001C12.7679 49 11.6609 49.7535 11.2091 50.8999C10.7572 52.0464 11.0524 53.3525 11.9532 54.1932L26.9539 68.1932Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                            Đăng xuất
                        </button>
                    </Fragment>
                )}
            </div>

            <div className="group-[.loading]:pointer-events-none group-[.loading]:opacity-50">
                {auth_account ? (
                    <div id="loginSection" className="rounded-md bg-[#F7F7F7] p-4 outline outline-1 -outline-offset-1 outline-line">
                        <div className="flex items-start gap-2">
                            <img
                                src={auth_account.img_url}
                                alt={auth_account.nickname}
                                className="w-10 h-10 rounded-full border border-gray-300"
                            />
                            <div className="flex flex-col">
                                <span className="player-name font-bold">{auth_account.nickname}</span>
                                <span className="player-id text-xs text-text-secondary">ID người chơi: {auth_account.account_id}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="rounded-md bg-[#F7F7F7] p-4 outline outline-1 -outline-offset-1 outline-line">
                        <div className="mb-4">
                            <label className="mb-2 flex items-center gap-1 text-[15px]/4 font-medium text-text-title">
                                ID đăng nhập
                                <button
                                    type="button"
                                    className="rounded-full text-sm outline-current transition-opacity hover:opacity-70 focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2"
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_489_1601)">
                                            <path
                                                d="M4.8999 5.39848C4.89981 4.44579 5.67209 3.67344 6.62478 3.67344H7.37471C8.33038 3.67344 9.09977 4.45392 9.09971 5.40371C9.09967 6.05546 8.73195 6.65677 8.14619 6.94967L7.57416 7.23571C7.49793 7.27382 7.44978 7.35173 7.44978 7.43695V7.49844C7.44978 7.78839 7.21473 8.02344 6.92478 8.02344C6.63483 8.02344 6.39978 7.78839 6.39978 7.49844V7.43695C6.39978 6.95403 6.67262 6.51255 7.10456 6.29657L7.6766 6.01053C7.90385 5.8969 8.0497 5.66087 8.04971 5.40365C8.04973 5.0279 7.74459 4.72344 7.37471 4.72344H6.62478C6.25203 4.72344 5.94987 5.02563 5.9499 5.39838C5.94993 5.68833 5.7149 5.9234 5.42495 5.92343C5.135 5.92346 4.89993 5.68843 4.8999 5.39848Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                d="M6.9999 10.1484C7.3865 10.1484 7.6999 9.83504 7.6999 9.44844C7.6999 9.06184 7.3865 8.74844 6.9999 8.74844C6.6133 8.74844 6.2999 9.06184 6.2999 9.44844C6.2999 9.83504 6.6133 10.1484 6.9999 10.1484Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.524902 6.99844C0.524902 3.42239 3.42386 0.523438 6.9999 0.523438C10.5759 0.523438 13.4749 3.42239 13.4749 6.99844C13.4749 10.5745 10.5759 13.4734 6.9999 13.4734C3.42386 13.4734 0.524902 10.5745 0.524902 6.99844ZM6.9999 1.57344C4.00376 1.57344 1.5749 4.00229 1.5749 6.99844C1.5749 9.99458 4.00376 12.4234 6.9999 12.4234C9.99605 12.4234 12.4249 9.99458 12.4249 6.99844C12.4249 4.00229 9.99605 1.57344 6.9999 1.57344Z"
                                                fill="currentColor"
                                            ></path>
                                        </g>
                                        <defs>
                                            <clipPath>
                                                <rect width="14" height="14" fill="currentColor"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </label>
                            <div className="flex">
                                <div className="relative grow">
                                    <input
                                        id="account_id"
                                        className="form-input w-full px-4 ltr:rounded-r-none ltr:border-r-0 rtl:rounded-l-none rtl:border-l-0"
                                        type="text"
                                        placeholder="ID người chơi"
                                        style={{ borderRightWidth: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        value={accountID}
                                        onChange={(e) => handleOnChangeAccountID(e.target.value)}
                                    />
                                </div>

                                <button
                                    id="btn-login-id"
                                    className="shrink-0 rounded-md bg-primary-red px-5 py-[15px] text-sm/none font-bold text-white transition-colors hover:bg-primary-red-hover focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2 rounded-s-none"
                                    onClick={handleLoginAccountID}
                                >
                                    Đăng nhập
                                </button>
                            </div>

                            <div id="error-login-id" className="mt-2 text-xs/none text-primary-red md:text-sm/none hidden"></div>
                        </div>

                        <div className="flex items-center gap-4 text-xs/normal text-text-secondary md:text-sm/[22px]">
                            <span className="me-auto">Hoặc đăng nhập bằng các phương thức sau</span>

                            <Link
                                to="/facebook-login"
                                className="shrink-0 rounded-full p-1.5 transition-opacity hover:opacity-70 bg-[#006AFC]"
                            >
                                <img
                                    className="h-5 w-5 brightness-0 invert"
                                    src="https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/ic-fb-485c92b0.svg"
                                    alt="Facebook logo"
                                />
                            </Link>
                            <Link
                                to="/google-login"
                                className="shrink-0 rounded-full p-1.5 transition-opacity hover:opacity-70 outline outline-1 -outline-offset-1 outline-line bg-white"
                            >
                                <img
                                    className="h-5 w-5"
                                    src="https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/ic-google-d2ceaa95.svg"
                                    alt="Google logo"
                                />
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LoginAccount;
